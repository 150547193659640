import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import {
  useAdminUploadUploadIdRecordRecordIdPatch,
  useAdminUploadUploadIdRecordRecordIdGet,
  useLookupCountryCountryIdSubdivisionGet,
  useAdminImportTmplProviderIdVersionVersionIdGet,
  useAdminLevelGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getCountries } from "@services/Network";
import { enqueueSnackbar } from "notistack";
import { Loader } from "@components/crud/Loader";
import { capitalizeEveryWord } from "@utils/capitalize";
import { GENDERS, SUFFIX_OPTIONS } from "@utils/constants";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { stringJoin } from "@utils/stringJoin";

interface fieldMapFields {
  available: boolean;
  canBeEmpty: boolean;
  isRequired: boolean;
  header: string;
}
interface fieldMap {
  player: {
    firstName: fieldMapFields;
    middleName: fieldMapFields;
    lastName: fieldMapFields;
    email: fieldMapFields;
    address1: fieldMapFields;
    address2: fieldMapFields;
    city: fieldMapFields;
    state: fieldMapFields;
    zip: fieldMapFields;
    country: fieldMapFields;
    birthedAt: fieldMapFields;
    level: fieldMapFields;
    suffix: fieldMapFields;
  };
  parent1: {
    firstName: fieldMapFields;
    lastName: fieldMapFields;
    email: fieldMapFields;
    phone: fieldMapFields;
  };
  parent2: {
    firstName: fieldMapFields;
    lastName: fieldMapFields;
    email: fieldMapFields;
    phone: fieldMapFields;
  };
}

const StyledHeader = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 400,
  letterSpacing: "1.2px",
  textTransform: "uppercase",
  marginBottom: "18px"
}));
export const FixFailedRow = () => {
  const navigate = useNavigate();
  const orgId = useRecoilValue(organizationAtom);
  const orgs = useRecoilValue(organizationsAtom);
  const currentOrg = orgs.find((org) => org.organizationId === orgId);
  const { uploadId, fixId } = useParams();
  const { data, isLoading } = useAdminUploadUploadIdRecordRecordIdGet(
    uploadId!,
    fixId!,
    {}
  );

  const record = data?.data?.record as {
    player: {
      firstName: string;
      middleName: string;
      lastName: string;
      email: string;
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      birthedAt: string;
      level: string;
      suffix: string;
      gender: string;
    };
    parent1: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
    parent2: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
  const {
    watch,
    handleSubmit,
    control,
    setError,
    reset,
    setValue,
    trigger,
    formState: { isValid }
  } = useForm({
    mode: "all",
    defaultValues: {
      country: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      playerFirstName: "",
      playerMiddleName: "",
      playerLastName: "",
      playerDateOfBirth: "",
      playerGender: "",
      playerLevel: "",
      playerSuffix: "",
      playerEmail: "",
      parent1FirstName: "",
      parent1LastName: "",
      parent1Email: "",
      parent1Phone: "",
      parent2FirstName: "",
      parent2LastName: "",
      parent2Email: "",
      parent2Phone: ""
    }
  });
  useEffect(() => {
    if (data != undefined) {
      const defaultValues = {
        country: record.player.country || "",
        addressLine1: record.player.address1 || "",
        addressLine2: record.player.address2 || "",
        city: record.player.city || "",
        state: record.player.state || "",
        zip: record.player.zip || "",
        playerFirstName: record.player.firstName || "",
        playerMiddleName: record.player.middleName || "",
        playerLastName: record.player.lastName || "",
        playerDateOfBirth: !isNaN(new Date(record.player.birthedAt).getTime())
          ? `${new Date(record.player.birthedAt).getFullYear()}-${(
              new Date(record.player.birthedAt).getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${new Date(record.player.birthedAt)
              .getDate()
              .toString()
              .padStart(2, "0")}`
          : "",
        playerGender:
          record.player?.gender && record.player?.gender != ""
            ? record.player?.gender.toUpperCase()
            : "",
        playerLevel:
          record.player.level &&
          levelOptions.find((l) => l.value == record.player.level)
            ? record.player.level
            : "",
        playerSuffix: record.player.suffix || "",
        playerEmail: record.player.email || "",
        parent1FirstName: record.parent1.firstName || "",
        parent1LastName: record.parent1.lastName || "",
        parent1Email: record.parent1.email || "",
        parent1Phone: record.parent1.phone || "",
        parent2FirstName: record.parent2.firstName || "",
        parent2LastName: record.parent2.lastName || "",
        parent2Email: record.parent2.email || "",
        parent2Phone: record.parent2.phone || ""
      };
      reset(defaultValues, {
        keepDirtyValues: true
      });
    }
  }, [data]);

  const country = watch("country");
  const { data: countryData, isLoading: isCountryDataLoading } = getCountries({
    query: {
      staleTime: Infinity,
      cacheTime: 0
    }
  });
  const { data: importTemplate, isLoading: isTemplateLoading } =
    useAdminImportTmplProviderIdVersionVersionIdGet(
      currentOrg?.providerId as string,
      currentOrg?.versionId as string
    );
  const { data: levels, isLoading: isLevelLoading } = useAdminLevelGet({
    organizationId: orgId!,
    pageSize: 1000
  });
  const { data: stateData, isLoading: isStateDataLoading } =
    useLookupCountryCountryIdSubdivisionGet(country, {
      query: {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!country
      }
    });
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { mutate, isLoading: isSaving } =
    useAdminUploadUploadIdRecordRecordIdPatch();
  const saveHandler = (formValues) => {
    const data = {
      player: {
        firstName: formValues.playerFirstName,
        middleName: formValues.playerMiddleName,
        lastName: formValues.playerLastName,
        email: formValues.playerEmail,
        address1: formValues.addressLine1,
        address2: formValues.addressLine2,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
        country: formValues.country,
        birthedAt: formValues.playerDateOfBirth,
        level: formValues.playerLevel,
        suffix: formValues.playerSuffix,
        gender: formValues.playerGender
      },
      parent1: {
        firstName: formValues.parent1FirstName,
        lastName: formValues.parent1LastName,
        email: formValues.parent1Email,
        phone: formValues.parent1Phone
      },
      parent2: {
        firstName: formValues.parent2FirstName,
        lastName: formValues.parent2LastName,
        email: formValues.parent2Email,
        phone: formValues.parent2Phone
      }
    };
    if (!(importTemplate?.data?.fieldMap as fieldMap).parent2) {
      delete data.parent2;
    }
    mutate(
      {
        uploadId: uploadId!,
        recordId: fixId!,
        data
      },
      {
        onSuccess: () => {
          navigate(`/import-players/${uploadId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to fix the record", {
            variant: "error"
          });
        }
      }
    );
  };
  const countryOptions = useMemo(() => {
    if (countryData?.length) {
      return countryData
        .map((country) => ({
          value: country.countryId as string,
          label: country.name as string
        }))
        .sort((c1, c2) => {
          if (c1.value === "US") return -1;
          if (c2.value === "US") return 1;
          return 0;
        });
    }
    return [];
  }, [countryData]);
  const levelOptions = useMemo(() => {
    if (levels?.data.levels && levels?.data?.levels.length > 0) {
      return levels.data.levels.map((level) => ({
        value: level.levelId as string,
        label: level.name as string
      }));
    }
    return [];
  }, [levels]);
  const selectedCountry = useMemo(() => {
    if (countryData?.length) {
      return countryData?.find((c) => c.countryId === country);
    }
    return null;
  }, [country, countryData]);

  const stateOptions = useMemo(() => {
    if (stateData?.data?.length) {
      return stateData.data.map((state) => ({
        value: state.subdivisionId as string,
        label: state.name as string
      }));
    }
    return [];
  }, [stateData]);
  const validateFields = () => {
    const formValues = watch();
    if (formValues.playerFirstName === "") {
      setError("playerFirstName", { message: "First Name is required" });
    }
    if (
      formValues.playerMiddleName === "" &&
      (importTemplate?.data?.fieldMap as fieldMap)?.player?.middleName
        ?.isRequired
    ) {
      setError("playerMiddleName", { message: "Middle Name is required" });
    }
    if (formValues.playerLastName === "") {
      setError("playerLastName", { message: "Last Name is required" });
    }
    if (formValues.playerEmail === "") {
      setError("playerEmail", { message: "Email is required" });
    }
    if (formValues.playerGender === "") {
      setError("playerGender", { message: "Gender is required" });
    }
    if (
      formValues.playerLevel === "" &&
      (importTemplate?.data?.fieldMap as fieldMap)?.player?.level?.isRequired
    ) {
      setError("playerLevel", { message: "Level is required" });
    }
    if (
      formValues.playerSuffix === "" &&
      (importTemplate?.data?.fieldMap as fieldMap)?.player?.suffix?.isRequired
    ) {
      setError("playerSuffix", { message: "Suffix is required" });
    }
    if (formValues.playerDateOfBirth === "") {
      setError("playerDateOfBirth", { message: "Date of Birth is required" });
    }
    if (formValues.country === "") {
      setError("country", { message: "Country is required" });
    }
    if (formValues.addressLine1 === "") {
      setError("addressLine1", { message: "Address 1 is required" });
    }
    if (formValues.city === "") {
      setError("city", { message: "City is required" });
    }
    if (formValues.state === "") {
      setError("state", { message: "State/Province is required" });
    }
    if (formValues.zip === "") {
      setError("zip", { message: "Zip Code is required" });
    }
    if (formValues.parent1FirstName === "") {
      setError("parent1FirstName", { message: "First Name is required" });
    }
    if (formValues.parent1LastName === "") {
      setError("parent1LastName", { message: "Last Name is required" });
    }
    if (formValues.parent1Email === "") {
      setError("parent1Email", { message: "Email is required" });
    }
    if (formValues.parent1Phone === "") {
      setError("parent1Phone", { message: "Mobile Phone is required" });
    }
    if ((importTemplate?.data?.fieldMap as fieldMap)?.parent2) {
      if (formValues.parent2FirstName === "") {
        setError("parent2FirstName", { message: "First Name is required" });
      }
      if (formValues.parent2LastName === "") {
        setError("parent2LastName", { message: "Last Name is required" });
      }
      if (formValues.parent2Email === "") {
        setError("parent2Email", { message: "Email is required" });
      }
      if (formValues.parent2Phone === "") {
        setError("parent2Phone", { message: "Mobile Phone is required" });
      }
    }
  };
  useEffect(() => {
    if (data?.data) validateFields();
  }, [data, importTemplate?.data]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = stringJoin(" ", streetNumber?.long_name, route?.long_name);
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue("addressLine1", address1);
    if (subpremise) setValue("addressLine2", subpremise?.long_name);
    setValue("country", country?.short_name);
    setValue("state", state?.short_name);
    setValue("city", city?.long_name);
    setValue("zip", zip?.long_name);
    trigger("addressLine1");
    trigger("country");
    trigger("state");
    trigger("city");
    trigger("zip");
  };

  return (
    <>
      <Loader isLoading={isLoading || isTemplateLoading || isLevelLoading}>
        <Container>
          <Toolbar
            title="Fix User Upload Record"
            backBtnClick={() => navigate(`/import-players/${uploadId}`)}
          />
          <Form>
            <StyledHeader>Athlete / Player Details</StyledHeader>
            {(() => {
              const player = (importTemplate?.data?.fieldMap as fieldMap)
                ?.player;
              return player ? (
                <>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid data-testid="fix-row-player.firstName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="playerFirstName"
                        type="text"
                        label="First Name"
                        required={true}
                        onChange={(e) =>
                          setValue(
                            "playerFirstName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                        rules={{
                          required: "First Name is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.middleName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="playerMiddleName"
                        type="text"
                        label="Middle Name"
                        required={player.middleName.isRequired}
                        rules={
                          player.middleName.isRequired
                            ? {
                                required: "Middle Name is required"
                              }
                            : {}
                        }
                        onChange={(e) =>
                          setValue(
                            "playerMiddleName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.lastName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="playerLastName"
                        type="text"
                        label="Last Name"
                        required={true}
                        rules={{
                          required: "Last Name is required"
                        }}
                        onChange={(e) =>
                          setValue(
                            "playerLastName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.suffix" xs={6} md={6}>
                      <FormSelect
                        control={control}
                        name="playerSuffix"
                        options={SUFFIX_OPTIONS}
                        label="Suffix"
                        required={player.suffix.isRequired}
                        rules={
                          player.suffix.isRequired
                            ? {
                                required: "Suffix is required"
                              }
                            : {}
                        }
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.gender" xs={6} md={6}>
                      <FormSelect
                        control={control}
                        name="playerGender"
                        label="Gender"
                        required={true}
                        rules={{
                          required: "Gender is required"
                        }}
                        options={GENDERS}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.birthedAt" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="playerDateOfBirth"
                        type="date"
                        label="Date of Birth"
                        required={true}
                        rules={{
                          required: "Date of Birth is required"
                        }}
                      />
                    </Grid>

                    <Grid data-testid="fix-row-player.email" xs={6} md={6}>
                      <FormInput
                        type="text"
                        control={control}
                        name="playerEmail"
                        label="Email"
                        required={true}
                        rules={{
                          required: "Email is required",
                          validate: (value) => {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            return emailRegex.test(value) || "Invalid email";
                          }
                        }}
                      />
                    </Grid>

                    <Grid data-testid="fix-row-player.level" xs={6} md={6}>
                      <FormSelect
                        control={control}
                        name="playerLevel"
                        label="Level"
                        required={player.level.isRequired}
                        rules={
                          player.level.isRequired
                            ? { required: "Level is required" }
                            : {}
                        }
                        options={levelOptions}
                      />
                    </Grid>
                  </Grid>
                  <StyledHeader sx={{ marginTop: "24px" }}>
                    ADDRESS DETAILS
                  </StyledHeader>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid data-testid="fix-row-player.country" xs={6} md={6}>
                      <FormSelect
                        control={control}
                        name="country"
                        label="Country"
                        options={countryOptions}
                        isLoading={isCountryDataLoading}
                        required={true}
                        rules={{
                          required: "Country is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.address1" xs={6} md={6}>
                      <AddressAutocomplete
                        control={control}
                        name="addressLine1"
                        label="Address Line 1"
                        required={true}
                        selectSuggestion={selectAddressSuggestion}
                        rules={{
                          required: "Address 1 is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.address2" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="addressLine2"
                        type="text"
                        label="Address Line 2"
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.city" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="city"
                        type="text"
                        label="City"
                        required={true}
                        rules={{
                          required: "City is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.state" xs={6} md={6}>
                      <FormSelect
                        name="state"
                        options={stateOptions}
                        required
                        label="State/Province"
                        control={control}
                        rules={{
                          required: "State/Province is required"
                        }}
                        isLoading={!!country && isStateDataLoading}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-player.zip" xs={6} md={6}>
                      <FormInput
                        type="text"
                        control={control}
                        name="zip"
                        label="Zip Code"
                        required={true}
                        rules={{
                          required: "Zip Code is required"
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null;
            })()}
            {(() => {
              const parent1 = (importTemplate?.data?.fieldMap as fieldMap)
                ?.parent1;
              return parent1 ? (
                <>
                  <StyledHeader sx={{ marginTop: "24px" }}>
                    PARENT / GUARDIAN 1 DETAILS
                  </StyledHeader>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid data-testid="fix-row-parent1.firstName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="parent1FirstName"
                        type="text"
                        label="First Name"
                        required={true}
                        onChange={(e) =>
                          setValue(
                            "parent1FirstName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                        rules={{
                          required: "First Name is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-parent1.lastName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="parent1LastName"
                        type="text"
                        label="Last Name"
                        required={true}
                        onChange={(e) =>
                          setValue(
                            "parent1LastName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                        rules={{
                          required: "Last Name is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-parent1.phone" xs={6} md={6}>
                      <FormInput
                        name="parent1Phone"
                        type="tel"
                        required
                        label="Mobile Phone"
                        control={control}
                        rules={{
                          required: "Mobile Phone is required"
                        }}
                        country={selectedCountry}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-parent1.email" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="parent1Email"
                        type="text"
                        label="Email"
                        required={true}
                        rules={{
                          required: "Email is required",
                          validate: (value) => {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            return emailRegex.test(value) || "Invalid email";
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null;
            })()}
            {(() => {
              const parent2 = (importTemplate?.data?.fieldMap as fieldMap)
                ?.parent2;
              return parent2 ? (
                <>
                  <StyledHeader sx={{ marginTop: "24px" }}>
                    PARENT / GUARDIAN 2 DETAILS
                  </StyledHeader>
                  <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid data-testid="fix-row-parent2.firstName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="parent2FirstName"
                        type="text"
                        label="First Name"
                        required={true}
                        onChange={(e) =>
                          setValue(
                            "parent2FirstName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                        rules={{
                          required: "First Name is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-parent2.lastName" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="parent2LastName"
                        type="text"
                        label="Last Name"
                        onChange={(e) =>
                          setValue(
                            "parent2LastName",
                            //@ts-ignore
                            capitalizeEveryWord(e.target.value)
                          )
                        }
                        required={true}
                        rules={{
                          required: "Last Name is required"
                        }}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-parent2.phone" xs={6} md={6}>
                      <FormInput
                        name="parent2Phone"
                        type="tel"
                        required
                        label="Mobile Phone"
                        control={control}
                        rules={{
                          required: "Mobile Phone is required"
                        }}
                        country={selectedCountry}
                      />
                    </Grid>
                    <Grid data-testid="fix-row-parent2.email" xs={6} md={6}>
                      <FormInput
                        control={control}
                        name="parent2Email"
                        type="text"
                        label="Email"
                        required={true}
                        rules={{
                          required: "Email is required",
                          validate: (value) => {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            return emailRegex.test(value) || "Invalid email";
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null;
            })()}
          </Form>
          <Footer
            cancelBtnClick={() => setOpenCancelDialog(true)}
            saveBtnClick={handleSubmit(saveHandler)}
            saveBtnLabel="Process"
            isDisabled={!isValid || isSaving}
            isLoading={isSaving}
          />
          <ConfirmationDialog
            title="Are you sure you want to cancel?"
            body="All of your current changes will be lost."
            open={openCancelDialog}
            close={() => setOpenCancelDialog(false)}
            onCancel={() => setOpenCancelDialog(false)}
            onConfirm={() => navigate(`/import-players/${uploadId}`)}
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
          />
        </Container>
      </Loader>
    </>
  );
};
