import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Grid } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { TeamsProgramsSettings } from "./TeamsProgramsSettings";
import { Form } from "@components/crud/Form";
import { organizationAtom } from "@recoil/auth";
import { useRecoilState } from "recoil";
import { LiveStreamingSetting } from "./LiveStreamingSetting";
import { SubscriptionBillingSettings } from "./SubscriptionsBillingSettings";
import { CalendarSetting } from "./CalendarSetting";
import { MobileApp } from "./MobileApp";
import { ProductEngineering } from "./ProductEngineering";
import { FeedSetting } from "./FeedSetting";
import { DashboardSetting } from "./DashboardSetting";
import { MarketingSetting } from "./MarketingSetting";

export const Settings = () => {
  const [organizationId] = useRecoilState(organizationAtom);
  const [tab, setTab] = useState(organizationId ? "Dashboard" : "Mobile App");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
  };
  return (
    <Container>
      <Toolbar
        title="Settings"
        tabs={{
          tabs: organizationId
            ? ["Dashboard", "Calendar", "Teams/Programs", "Live Streaming"]
            : [
                "Mobile App",
                "Feed",
                "Dashboard",
                "Marketing",
                "Product & Engineering"
              ],
          onTabChange: onTabChange,
          activeTab: tab
        }}
      />
      <Form>
        <Grid item container direction="column" spacing={3}>
          {tab === "Calendar" && organizationId && <CalendarSetting />}
          {tab === "Mobile App" && <MobileApp />}
          {tab === "Teams/Programs" && <TeamsProgramsSettings />}
          {tab === "Subscriptions and Billing" && (
            <SubscriptionBillingSettings />
          )}
          {tab === "Live Streaming" && <LiveStreamingSetting />}
          {tab === "Product & Engineering" && <ProductEngineering />}
          {tab === "Feed" && <FeedSetting />}
          {tab === "Dashboard" && <DashboardSetting />}
          {tab === "Marketing" && <MarketingSetting />}
        </Grid>
      </Form>
    </Container>
  );
};
