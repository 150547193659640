import { Loader } from "@components/crud/Loader";
import { Box, Grid, styled, Typography } from "@mui/material";
import {
  ModelConfig,
  ModelSetting,
  useAdminSettingsGet,
  useAdminSettingsPut,
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Footer } from "@components/crud/Footer";
import { Button } from "@components/Button";
import { Edit } from "@mui/icons-material";
import { FormSelect } from "@components/FormSelect";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { hasPermission } from "@services/Casbin";

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: 1.5rem;
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
  padding-bottom: 16px;
`;

export const DashboardSetting = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const [editEnabled, setEditEnabled] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const {
    control,
    getValues,
    reset,
    formState: { isDirty }
  } = useForm({
    mode: "onBlur"
  });

  const { data: settings, isLoading: isLoading } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        parentId: "org-dashboard",
        organizationId: organizationId!
      });

  const { mutate: save, isLoading: isSaving } = !organizationId
    ? useConfigPut()
    : useAdminSettingsPut();
  const onSave = () => {
    const data = getValues();
    const formattedData: Array<{
      settingId?: string;
      key?: string;
      value: string;
    }> = [];
    Object.keys(data).forEach((key) => {
      const newKey = key.replace(/_/g, ".");
      if (data[key]) {
        if (!organizationId) {
          formattedData.push({ key: newKey, value: data[key] });
        } else {
          formattedData.push({ settingId: newKey, value: data[key] });
        }
      }
    });
    save(
      {
        data: formattedData,
        ...(organizationAtom
          ? { params: { organizationId: organizationId! } }
          : {})
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          setEditEnabled(false);
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    if (settings?.data) {
      const formData = {};
      if (!organizationId) {
        settings.data
          .filter(
            //@ts-ignore
            (s) => s.key?.includes("dashboard.") && s.type == "SINGLE_SELECT"
          )
          .map((item) => {
            if (item.key) {
              formData[item.key.replace(/\./g, "_")] = item.value;
            }
          });
      }
      if (organizationId) {
        settings.data.map((item) => {
          if (item.settingId) {
            formData[item.settingId.replace(/\./g, "_")] =
              item.organizationSettings && item.organizationSettings.length
                ? item.organizationSettings[0].value
                : item.default;
          }
        });
      }
      reset(formData);
    }
  }, [settings]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setDisabled(!edit);
    };
    fetchPermissions();
  }, []);

  return (
    <Grid item container direction="column" spacing="15px">
      <Loader isLoading={isLoading}>
        <StyledContainer>
          <Grid container spacing={3}>
            <Grid item xs={!editEnabled ? 6 : 12}>
              <StyledTypography>SportsGravy Dashboard</StyledTypography>
            </Grid>
            {!editEnabled && !disabled && (
              <Grid container item xs={6} justifyContent="flex-end">
                <Button
                  variant="admin-primary"
                  onClick={() => setEditEnabled(true)}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              </Grid>
            )}
          </Grid>
          {(organizationId
            ? (settings?.data as ModelSetting[]) ?? []
            : (settings?.data as ModelConfig[])
                ?.filter(
                  (item) =>
                    item.key?.includes("dashboard.") &&
                    item.type === "SINGLE_SELECT"
                )
                .sort((a, b) => a?.order - b?.order) ?? []
          ).map((item, index) => {
            const key = !organizationId ? item.key : item.settingId;
            return (
              <Grid
                key={index}
                container
                xs={6}
                sx={{ paddingBottom: "24px" }}
                data-testid={key}
              >
                <FormSelect
                  control={control}
                  name={(key as string).replace(/\./g, "_")}
                  label={item.name}
                  options={
                    item.options as Array<{
                      label: string;
                      value: string | number;
                    }>
                  }
                  disabled={!editEnabled}
                />
              </Grid>
            );
          })}
        </StyledContainer>
        {editEnabled && (
          <Footer
            saveBtnClick={onSave}
            isDisabled={!isDirty || !editEnabled || isSaving}
            isLoading={isSaving}
            cancelBtnClick={() => setOpenCancelDialog(true)}
          />
        )}
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => setEditEnabled(false)}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Loader>
    </Grid>
  );
};
